.modal {
	min-width: min(60vw, 600px);
}
$primary: #ba54f5;
.chatContainer {
	height: min(70vh, 450px);
	overflow-x: hidden;
	overflow-y: auto;
	padding-right: 10px;
}
.date {
	position: sticky;
	top: -4px;
	z-index: 10;
	background-color: white;
	text-align: center;
	font-size: 12px;
	color: $primary;
}
.chatItem {
	display: flex;
	align-items: center;
	gap: 4px;
}
.myChatItem {
	flex-direction: row-reverse;
	align-self: flex-end;
}
.senderBox {
	display: flex;
	height: 40px;
	width: 40px;
	min-width: 40px;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 50%;
	font-size: 14px;
	background-color: $primary;
	color: white;
}
.mySenderBox {
	border: 1px solid $primary;
	color: black;
	background-color: white;
}
.chatBox {
	word-wrap: break-word;
	border-radius: 3px;
	padding-inline: 8px;
	padding-top: 4px;
	border: 1px solid $primary;
}
.myChatBox {
	background-color: $primary;
	color: white;
	border: none;
	max-width: 50%;
}
.senderName {
	margin-bottom: -4px;
	font-size: 14px;
	font-weight: bold;
	display: block;
}
.mySenderName {
	align-self: flex-end;
	text-align: right;
}
.message {
	word-break: break-all;
	display: block;
}
.myMessage {
	text-align: right;
}
.dateTime {
	margin-bottom: 4px;
	margin-top: -4px;
	font-size: 10px;
	display: block;
}
.myDateTime {
	align-self: flex-end;
	text-align: right;
}
.form {
	width: 100%;
	display: flex;
	&Input {
		width: 90%;
		padding: 5px;
		border: none;
		outline: none;
	}
	&Submit {
		width: 10%;
		padding: 5px;
		outline: none;
		border: none;
		background: transparent;
		svg {
			fill: #e14eca;
		}
	}
}
