//colors

$primary: #3358f4;
$primary-light: #1d8cf8;

$secondary: #e14eca;
$secondary-dark: #ba54f5;

$white: #fff;

$body-color: #1d253b;
$body-bg: #f5f6fa;

$grey: #eeeeee;
