.grid {
	display: flex;
	gap: 24px;
}

.rightSec {
	flex-grow: 1;
	height: calc(100vh - 96px);
	overflow: auto;
}

.rightSec::-webkit-scrollbar {
	display: none;
}

.horizontal-scroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
