.emptyContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	height: 100%;

	&.loaderBox {
		z-index: 9;
		background-color: rgba(211, 211, 211, 0.404);
	}

	& .emptyImage {
		height: 280px;
		width: 280px;
	}
}

.pagination {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	// width: max-content;
	margin: 16px 0;
}
.MuiDataGrid-row:nth-of-type(even) {
	background-color: rgb(24, 120, 204) !important;
}
.datagrid-header {
	background-color: rgb(24, 120, 204);
}
