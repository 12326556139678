@import '../../styles/colors';

.Sidebar {
	font-size: 1rem;
	background: linear-gradient(to bottom left, #e14eca, #ba54f5);

	padding-top: 2rem;
	box-shadow: 0 2px 22px 0 rgb(0 0 0 / 10%), 0 4px 20px 0 rgb(0 0 0 / 15%);
	border-radius: 5px;
	min-width: 320px;
	height: calc(100vh - 96px);
	overflow: auto;

	.list {
		padding: 0.5rem 1rem;
		margin: 1rem;
		border-radius: 5px;
		display: flex;
		align-items: center;
		cursor: pointer;
		text-decoration: none;
		color: hsla(0, 0%, 100%, 0.8);

		&:hover span {
			font-weight: 500;
			color: $white;
		}

		span {
			font-weight: 300;
			font-size: 15px;
			margin-left: 12px;
			transition: all 0.3s ease-in-out;
		}

		& svg {
			height: 1.5rem;
			width: 1.5rem;
			transition: all 0.3s ease-in-out;
		}

		&:hover svg {
			color: $white;
			transform: scale(1.1);
		}
	}
	.dropdown {
		color: $white;
		font-weight: 500;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ffffff90;
		position: relative;
		cursor: pointer;
	}
	.isDropDownActive {
		background: #ba54f5;
		// box-shadow: 0px 2px 15px 1px #e49ed9 inset;
	}
	.dropdownIcon {
		transition: all 0.5s;
	}
	.dropdownIcon.isActive {
		rotate: 180deg;
	}
	.subSections {
		display: grid;
		grid-template-rows: 0fr;
		transition: grid-template-rows 0.5s ease-in-out;
		overflow: hidden !important;
	}
	.sectionContainer {
		min-height: 0;
	}
	.isSubSectionActive {
		grid-template-rows: 1fr;
	}
}
