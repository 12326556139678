.container{
display: flex;
gap: 20px;
}

.tabContainer{
   margin:0 10px;
}
.araTabContainer{
   margin:0 10px;
   width: 25%;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;
    &:hover{
        cursor: pointer;
    }
}

.label{
    margin:8px 20px;
    color: #757575;
}

.activeLable{
    margin:8px 20px;
    color: #Ba54f5;
}

.emptyContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	height: 100%;

	&.loaderBox {
		z-index: 9;
		background-color: rgba(211, 211, 211, 0.404);
	}

	& .emptyImage {
		height: 280px;
		width: 280px;
	}
}