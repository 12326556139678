.couponContainer {
	display: flex;
	flex-direction: column;
	background: #d650d61a;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}

.promotionContainer {
	display: flex;
	flex-direction: column;
	background: #d6e8f2;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.cashbackContainer {
	display: flex;
	flex-direction: column;
	background: #fdedec;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.userContainer {
	display: flex;
	flex-direction: column;
	background: #d5f5e3;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.categoryContainer {
	display: flex;
	flex-direction: column;
	background: #fcf3cf;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.brandContainer {
	display: flex;
	flex-direction: column;
	background: #fdf2e9;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.pageBannerContainer {
	display: flex;
	flex-direction: column;
	background: #dbdffd;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.bannerContainer {
	display: flex;
	flex-direction: column;
	background: #ebf5fb;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.giftCardContainer {
	display: flex;
	flex-direction: column;
	background: #ffa69a;
	border-radius: 10px;
	padding: 20px;
	color: #00000099;
	margin: 10px 5px;
	font-size: 1rem;
}
.label {
	margin-bottom: 10px;
	font-size: 1.1rem;
	color: black !important;
}
