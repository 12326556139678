.topBar {
	height: 80px;
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.logoWrapper {
	position: absolute;
	left: 50%;
	zoom: 1.1;
	transform: translateX(-50%);
}
.logo {
	width: 150px;

}
