@import '_colors.scss';
@import '_mixins.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: relative;
}

body {
	color: $body-color;
	margin: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $body-bg !important;
	padding: 0 16px;
}

a {
	text-decoration: none;
}

.addScroll {
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
	height: calc(100vh - 96px);
	overflow: scroll;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus {
	outline: none !important;
}

.MuiDataGrid-columnHeader--moving {
	background-color: $white !important;
}

.mui-button:disabled {
	background: rgba(0, 0, 0, 0.12) !important;

	& > div {
		--height: 12px;
	}
}

.head-line {
	height: 3px;
	width: 100%;
	background: linear-gradient(to bottom left, #e14eca, #ba54f5);
	position: fixed;
	top: 0;
	left: 0;
}

.formik-error {
	color: #d32f2f;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.66;
	letter-spacing: 0.03333em;
	text-align: left;
	margin-top: 4px;
	margin-right: 14px;
	margin-bottom: 0;
	margin-left: 0px;
}

.MuiFormHelperText-root.Mui-error {
	position: absolute;
	bottom: -25px;
	left: 0;
	margin-left: 0px;
}

.my-grid {
	display: flex;
	flex-direction: column !important;
	justify-content: flex-end;

	& > div {
		width: 100%;
	}
}

.MuiGrid-root > .MuiGrid-item {
	padding-top: 1rem !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
	outline: none !important;
}

.list.active:hover {
	& svg,
	& span {
		color: #ba54f5 !important;
		font-weight: 500;
	}
}
.tt-c {
	text-transform: capitalize;
}
.table-cell-truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
legend {
	display: none !important;
}

.upload-color {
	color: rgb(180, 180, 180);
}

.textWrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.arabic-error .MuiFormHelperText-root.Mui-error {
	right: 0;
	text-align: right;
}
#imageAlt-helper-text{
	color: #d32f2f;
}
#imageAltAra-helper-text{
	color: #d32f2f;
}